import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Flex, InfoNote, Money, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import React, { useState } from 'react'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { displayStatusesMapping } from './util'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { normalizePhone } from '../../../utils'

const PaymentContent = ({ id, paymentMethod, transaction, voidTransaction, refundTransaction }) => {
  const { t } = useTranslation()
  const cleanupPayeeEmail = (emailString) => {
    if (!emailString) return ''

    return emailString
      .split(/[;,]/)
      .map((email) => email.trim())
      .filter((email) => email.length > 0)
      .join(' ')
  }

  const descriptionData = [
    {
      label: t('status'),
      value: (
        <StatusBadge
          color={displayStatusesMapping[transaction.status]?.color}
          value={t(displayStatusesMapping[transaction.status]?.label)}
        />
      ),
    },
    {
      label: t('paymentMethod'),
      value: paymentMethod || transaction.paymentMethod?.title,
    },
    {
      label: t('details'),
      value: (
        <Tooltip
          className="text-ellipsis overflow-hidden max-w-72 whitespace-nowrap text-sm"
          content={transaction?.failureReason}
          placement="bottom">
          {transaction?.failureReason}
        </Tooltip>
      ),
      hidden: !transaction.failureReason,
    },
    {
      label: t('accountNo'),
      value: transaction.accountNumber,
    },
    {
      label: t('accountName'),
      value: transaction.companyName,
    },
    {
      label: t('location'),
      value: transaction.location?.name,
    },
    {
      label: t('paymentDate'),
      value: <FormattedDate date={transaction.createdAt} format={'MM/dd/yyyy hh:mm:ss a'} />,
    },
    {
      label: t('createdBy'),
      value: transaction.createdBy
        ? `${transaction.createdBy?.firstName} ${transaction.createdBy?.lastName}${
            transaction.createdBy?.status === 'archived' ? ' (deleted)' : ''
          }`
        : transaction.payeeName,
    },
    {
      label: t('receiptEmail'),
      value: cleanupPayeeEmail(transaction.payeeEmail),
    },
    {
      label: t('phoneNumber'),
      value: normalizePhone(transaction.payeePhoneNumber),
    },
  ]

  const paymentDescriptionData = [
    {
      label: `(+) ${t('cardConvenienceFee')}`,
      value: <Money value={transaction.feeAmountCents} />,
    },
  ]
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const loading = false
  return (
    <div className="flex flex-col mt-[-1.5rem]">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
            {t('payment')}
          </Text>
        </div>

        <div className="flex flex-row">
          <div className="mr-2">
            <InfoNote label={t('id')}>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {id || transaction?.id}
              </Text>
            </InfoNote>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-3">
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="paymentAmount" />
          <Money className="text-2xl mt-1 font-medium" value={transaction.amountCents} />
        </div>
      </div>

      <DividedList content={descriptionData} />

      <Text className="mt-10" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
        {t('paymentDetails')}
      </Text>

      <Flex alignItems="center" className="min-h-10" justifyContent="between">
        <Text fontWeight={fontWeight.BOLD}>{t('totalPayment')}</Text>
        <Money className="font-semibold text-xl" value={transaction.amountCents} />
      </Flex>

      <DividedList content={paymentDescriptionData} skipTopMargin />
      <Flex alignItems="center" className="border-t min-h-10" justifyContent="between">
        <Text fontWeight={fontWeight.BOLD}>{t('totalCharged')}</Text>
        <Money
          className="font-semibold text-xl"
          value={transaction.amountCents + transaction.feeAmountCents}
        />
      </Flex>

      <MemoField
        closeForm={() => null}
        entityId={transaction.id}
        initialValue={transaction.memo}
        isFormDirty={memoFormIsDirty}
        isLoading={loading}
        labelKey="memo"
        responseEntityName={'updatePayment'}
        setDirtyFormState={setMemoFormIsDirty}
        isDisabled
      />

      {transaction.attachment && (
        <Flex className="mt-4">
          <Button
            className="w-fit underline mt-3 text-left"
            label={transaction.attachment.fileName}
            onClick={() => window.open(transaction.attachment.url)}
            size={sizes.SM}
            testData="view-attachment"
            variant={buttonsVariants.LINK}
          />
        </Flex>
      )}

      {!!transaction.refundableAmountCents && (
        <Button
          className="mt-8"
          label={t('refund')}
          onClick={() => refundTransaction(transaction)}
          testData="refund-cta"
        />
      )}
      {transaction.voidable && (
        <Button
          className="mt-4"
          label={t('void')}
          onClick={() => voidTransaction(transaction)}
          testData="void-transaction"
          variant={buttonsVariants.SECONDARY}
        />
      )}
    </div>
  )
}

PaymentContent.propTypes = {
  id: PT.string,
  transaction: PT.object,
  paymentMethod: PT.string,
  refundTransaction: PT.func.isRequired,
  voidTransaction: PT.func.isRequired,
}

PaymentContent.defaultProps = {
  id: '',
  data: {},
  transaction: {},
}

export default PaymentContent
