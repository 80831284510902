import VALIDATION_ERRORS from '../constants/validationErrors'

export const validateRequiredField = (value) => {
  if ((!value && value !== 0) || value === '') {
    return VALIDATION_ERRORS.REQUIRED
  } else return undefined
}

export const validateDateRange = (startDate, endDate) => {
  if (endDate - startDate < 0) {
    return VALIDATION_ERRORS.START_DATE_BEFORE_LAST
  } else if (endDate - startDate === 0) {
    return VALIDATION_ERRORS.START_DATE_EQUALS_LAST
  } else {
    return undefined
  }
}

export const validateCreditCardNumber = (value) => {
  if (!value || value === '') {
    return VALIDATION_ERRORS.REQUIRED
  } else {
    const trimedValue = deleteSpaces(value)
    if (trimedValue?.length < 16) {
      return VALIDATION_ERRORS.SHORT_CARD_NUMBER
    } else {
      return undefined
    }
  }
}
export const validateCvc = (value) => {
  if (!value || value === '') {
    return VALIDATION_ERRORS.REQUIRED
  } else {
    if (value.length < 3) {
      return VALIDATION_ERRORS.SHORT_CVC
    } else {
      return undefined
    }
  }
}

export const deleteSpaces = (value) => {
  return value?.split('').filter((symbol) => symbol !== ' ')
}

export const validateEmail = (email) => {
  if (email) {
    const validateEmail = email.toLowerCase().match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    if (!validateEmail) {
      return VALIDATION_ERRORS.EMAIL
    }
  }
  return undefined
}

export const validateMultipleEmails = (emails) => {
  if (emails) {
    const emailList = emails
      .split(/[;,]/)
      .map((email) => email.trim())
      .filter((email) => email.length > 0)

    const emailRegex =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    for (const email of emailList) {
      if (!emailRegex.test(email.toLowerCase())) {
        return VALIDATION_ERRORS.EMAIL // Return error if any email is invalid
      }
    }
  }
  return undefined // No errors if all emails are valid
}

export const validatePhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const validatePhoneNumber = phoneNumber.match(
      // eslint-disable-next-line max-len
      /^\(\d{3}\)\s\d{3}-\d{4}$/,
    )
    if (!validatePhoneNumber) {
      return VALIDATION_ERRORS.PHONE_NUMBER
    }
  }
  return undefined
}

export const validateWebsiteURL = (website) => {
  if (website) {
    const validateWebsite = website.match(
      // eslint-disable-next-line max-len
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
    )
    if (!validateWebsite) {
      return VALIDATION_ERRORS.WEBSITE
    }
  }
  return undefined
}

export const validateZipCode = (zipCode) => {
  if (zipCode) {
    const validateZipCode = zipCode.match(
      // eslint-disable-next-line max-len
      /^[0-9]{5}(?:-[0-9]{4})?$/,
    )
    if (!validateZipCode) {
      return VALIDATION_ERRORS.ZIPCODE
    }
  }
  return undefined
}

export const validateEqualFields = (value1, value2, errorMessage = '') => {
  if (value1 !== value2) {
    return errorMessage
  } else return undefined
}

export const validateBankRoutingNumber = (value) => {
  const digits = value.split('').map((d) => parseInt(d, 10))
  const checksum =
    3 * (digits[0] + digits[3] + digits[6]) +
    7 * (digits[1] + digits[4] + digits[7]) +
    (digits[2] + digits[5] + digits[8])

  return checksum % 10 === 0 ? undefined : VALIDATION_ERRORS.BANK_ROUTING_NUMBER
}
