import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Input, Tabs, Text } from '../../../ui-kit'
import { Field, Form } from 'react-final-form'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { validateMultipleEmails, validateRequiredField } from '../../../utils/validators'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import PaymentResult from '../../../components/paymentResult/PaymentResult'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { createPaymentMethodTabs } from '../../../constants/paymentResults'
import CardIntegration, {
  getCardPaymentToken,
  validateCreditCardFields,
} from '../../../components/paymentType/CardIntegration'
import BankTransferForm, {
  validateBankAccountFields,
} from '../../../components/paymentType/BankTransferForm'
import { useCurrentVendor } from '../../../hooks/useCurrentVendor'
import FileUpload from '../../../ui-kit/components/inputs/FileUpload'
import { appendFormData, camelToSnake } from '../../../utils/utils'
import { useNotifications } from '../../../hooks/useNotifications'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import axios from 'axios'
import { useRequest } from '../../../hooks/useRequest'
import { GET } from '../../../constants/contacts'
import { operationResultMods, paymentStatus } from '../../../constants/operationResults'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { denormalizePhoneNumber, normalizePhone } from '../../../utils'

const ReceivePaymentForm = ({
  isOpened,
  isFormDirty,
  setDirtyFormState,
  closeForm,
  requestClose,
}) => {
  const vendorData = useCurrentVendor()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [paymentResult, setPaymentResult] = useState(null)
  const [paymentStatusRefreshCounter, setPaymentStatusRefreshCounter] = useState(0)
  const [failureReason, setFailureReason] = useState(null)
  const [activeTab, setActiveTab] = useState(createPaymentMethodTabs.CARD)
  const defaultPaymentDetails = useMemo(
    () => ({
      paymentId: null,
      createdBy: null,
      companyName: null,
      paymentDate: null,
      accountNumber: null,
      paymentAmount: null,
      paymentMethodTitle: null,
    }),
    [],
  )
  const [paymentDetails, setPaymentDetails] = useState(defaultPaymentDetails)

  const locationsList = useMemo(
    () =>
      vendorData.locations
        ? vendorData.locations
            .filter((item) => !item.archivedAt)
            .map?.((item) => ({
              value: item.id,
              label: item.name,
              testData: item.name,
            }))
        : [],
    [vendorData?.locations],
  )

  const tabsMap = useMemo(
    () =>
      Object.values(createPaymentMethodTabs).map((tab) => ({
        label: t(tab),
        onClick: () => setActiveTab(tab),
        active: activeTab === tab,
      })),
    [activeTab],
  )

  const initialValues = useMemo(
    () => ({
      payee_name: `${currentUser?.firstName} ${currentUser?.lastName}`,
      companyName: '',
      accountNumber: '',
      amountCents: '',
      locationId: null,
      cardPaymentMethod: { zipCode: '' },
      achPaymentMethod: {
        accountHolderName: '',
        routingNumber: '',
        accountNumber: '',
        checkType: 'personal',
        accountType: 'checking',
      },
      payeeEmail: '',
      memo: '',
    }),
    [],
  )

  const { newNotification } = useNotifications()

  const [submit, { loading }] = useCustomMutation({
    onCompleted: (response) => {
      processPaymentResult(response)
    },
    onFailed: (error) => {
      processPaymentResult(null, error)
    },
    rollbarOptions: { operationName: 'create_payment_transaction', target: 'PaymentsTab' },
    mutationOptions: {
      mutationFn: () => {
        return axios.post(`${vendorData?.slug}/payment_transactions`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          isFromData: true,
        })
      },
      mutationKey: ['create_payment_transaction'],
    },
  })

  const onSubmit = useCallback(
    async (values) => {
      let data = null

      if (activeTab === createPaymentMethodTabs.CARD) {
        data = await getCardPaymentToken()
        if (!data || data.response_payload?.status === 'fail') {
          return newNotification({
            error: t('creditCardError'),
          })
        }
      }
      const { amountCents, achPaymentMethod, cardPaymentMethod, ...rest } = values
      const amountCentsValue = Number(amountCents.replaceAll(',', ''))

      const variables = {
        ...rest,
        paymentMethodConfigurationId:
          activeTab === createPaymentMethodTabs.CARD
            ? vendorData?.creditCardPaymentMethodConfiguration?.id
            : vendorData?.achPaymentMethodConfiguration?.id,
        amountCents: Math.round(amountCentsValue * 100),
        paymentMethodData:
          activeTab === createPaymentMethodTabs.ACH
            ? camelToSnake(achPaymentMethod)
            : { ...camelToSnake(cardPaymentMethod), response_payload: data },
      }
      if (values.payeePhoneNumber) {
        variables.payeePhoneNumber = denormalizePhoneNumber(values.payeePhoneNumber)
      }

      appendFormData(formData, { payment_transaction: camelToSnake(variables) })
      await submit()
    },
    [activeTab],
  )

  useEffect(() => {
    if (!isOpened) {
      setPaymentResult(null)
      setFailureReason(null)
      setPaymentStatusRefreshCounter(0)
    }
  }, [isOpened])

  const formData = useMemo(() => {
    return new FormData()
  }, [])

  const [paymentUuid, setPaymentUuid] = useState(null)

  useEffect(() => {
    let timeoutId = null

    const callback = async () => {
      if (paymentStatusRefreshCounter === 0) {
        return
      }

      const { data, error } = await useRequest({
        url: `${vendorData?.slug}/payment_transactions/${paymentUuid}`,
        method: GET,
        navigate,
      })
      processPaymentResult(data, error)

      if (data?.status !== paymentStatus.ENQUEUED && data?.status !== paymentStatus.PROCESSING) {
        return
      }

      timeoutId = setTimeout(() => {
        setPaymentStatusRefreshCounter((prevState) => prevState + 1)
      }, 3000)
    }
    callback()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [paymentStatusRefreshCounter])

  const processPaymentResult = (payment, error) => {
    if (error) {
      setPaymentStatusRefreshCounter(0)
      setPaymentResult(operationResultMods.SERVER_ERROR)
    }
    if (
      payment?.status === paymentStatus.ENQUEUED ||
      payment?.status === paymentStatus.PROCESSING
    ) {
      setPaymentUuid(payment?.id)
      if (!paymentStatusRefreshCounter) {
        setPaymentStatusRefreshCounter((prevState) => prevState + 1)
      }
      setPaymentResult(operationResultMods.LOADING)
      return
    }
    if (payment?.status === paymentStatus.SUBMITTED || payment?.status === paymentStatus.PAID) {
      setPaymentStatusRefreshCounter(0)
      setFailureReason(payment.failureReason)
      setPaymentDetails({
        paymentId: payment.id,
        createdBy: payment.payeeName,
        accountName: payment.companyName,
        paymentDate: payment.createdAt,
        accountNumber: payment.accountNumber,
        paymentAmount: payment.amountCents,
        chargedAmountCents: payment.chargedAmountCents,
        paymentMethodTitle: payment.paymentMethod?.title,
      })
      if (activeTab === createPaymentMethodTabs.CARD) {
        setPaymentResult(operationResultMods.CREDIT_CARD_SUCCESS)
      } else {
        setPaymentResult(operationResultMods.ACH_SUCCESS)
      }
      return
    }
    if (payment?.status === paymentStatus.FAILED) {
      setPaymentStatusRefreshCounter(0)
      if (activeTab === createPaymentMethodTabs.CARD) {
        setPaymentResult(operationResultMods.CREDIT_CARD_FAILURE)
      } else {
        setPaymentResult(operationResultMods.ACH_FAILURE)
      }
    }
  }

  const clearentConfiguration = vendorData?.creditCardPaymentMethodConfiguration?.paymentFormData

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, submitError, submitting, submitFailed }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return paymentResult ? (
          <PaymentResult
            data={paymentDetails}
            failureReason={failureReason}
            mode={paymentResult}
            onDoneClick={closeForm}
            setMode={setPaymentResult}
          />
        ) : (
          <form className="overflow-hidden w-[750px]" onSubmit={handleSubmit}>
            <Flex className="gap-3 mt-4">
              <div className="w-6/12">
                <Field name="companyName">
                  {({ input, meta }) => (
                    <>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('accountName')}
                      </Text>
                      <Input
                        {...input}
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                      />
                    </>
                  )}
                </Field>
              </div>

              <div className="w-6/12">
                <Field name="accountNumber">
                  {({ input, meta }) => (
                    <>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('accNumber')}
                      </Text>
                      <Input
                        {...input}
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                      />
                    </>
                  )}
                </Field>
              </div>
            </Flex>
            <Flex className="gap-3 mt-4">
              <div className="w-6/12">
                <Field name="amountCents">
                  {({ input, meta }) => {
                    return (
                      <Flex column>
                        <Text
                          className="pb-1"
                          color={colors.GRAY_700}
                          fontWeight={fontWeight.MEDIUM}
                          size={sizes.SM}>
                          {t('paymentAmount')}
                        </Text>
                        <CurrencyInput
                          error={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          name={input.name}
                          onChange={input.onChange}
                        />
                        {meta.error && meta.touched && meta.submitFailed ? (
                          <p className="pt-2 text-sm text-error">{meta.error}</p>
                        ) : null}
                      </Flex>
                    )
                  }}
                </Field>
              </div>

              <div className="w-6/12">
                <Field name="locationId">
                  {({ input, ...fieldProps }) => {
                    return (
                      <>
                        <NDropdown
                          errorMessage={
                            fieldProps.meta.error && fieldProps.meta.submitFailed
                              ? fieldProps.meta.error
                              : undefined
                          }
                          label={t('location')}
                          listClass="max-h-40"
                          onChange={input.onChange}
                          options={locationsList}
                          placeholder={t('location')}
                          testData="location"
                          value={input.value}
                          withSingleOptionAutoSelection
                        />
                      </>
                    )
                  }}
                </Field>
              </div>
            </Flex>
            <div className="w-full mt-6">
              <Tabs tabs={tabsMap} />
              {activeTab === createPaymentMethodTabs.CARD && (
                <CardIntegration
                  clearentConfiguration={clearentConfiguration}
                  creditCardConvenienceFeePercentage={vendorData?.creditCardFeePercentage}
                />
              )}
              {activeTab === createPaymentMethodTabs.ACH && <BankTransferForm />}
            </div>

            <div className="w-full mt-6">
              <Field name="payeeEmail">
                {({ input, meta }) => (
                  <>
                    <Text
                      className="pb-1"
                      color={colors.GRAY_700}
                      fontWeight={fontWeight.MEDIUM}
                      size={sizes.SM}>
                      {t('receiptEmailMultiple')}
                    </Text>
                    <Input
                      {...input}
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                    />
                  </>
                )}
              </Field>
            </div>
            <div className="w-full mt-4">
              <Field name="payeePhoneNumber" parse={normalizePhone}>
                {({ input, meta }) => {
                  return (
                    <Input
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      label={t('phoneNumber')}
                      placeholder={t('phoneNumber')}
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <FileUpload fieldName="payment_transaction[attachment]" formData={formData} />

            <div className="w-full mt-6">
              <Field name="memo">
                {({ input }) => {
                  return (
                    <Textarea
                      id={input.name}
                      inputClassName={'h-20'}
                      label={t('memoOptional')}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )
                }}
              </Field>
            </div>

            {submitFailed && submitError && !submitting ? (
              <p className="pt-2 text-sm text-error">{submitError}</p>
            ) : null}

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-receive-dep"
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                disabled={loading}
                label={t('receivePayment')}
                testData="submit-receive-dep"
                type="submit"
              />
            </div>
            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => {
        const basicValidators = {
          companyName: validateRequiredField(values.companyName),
          accountNumber: validateRequiredField(values.accountNumber),
          amountCents: validateRequiredField(values.amountCents),
          locationId: validateRequiredField(values.locationId),
          payeeEmail:
            validateRequiredField(values.payeeEmail) || validateMultipleEmails(values.payeeEmail),
        }

        const paymentMethodErrors =
          activeTab === createPaymentMethodTabs.ACH
            ? { achPaymentMethod: validateBankAccountFields(values.achPaymentMethod) }
            : { cardPaymentMethod: validateCreditCardFields(values.cardPaymentMethod) }

        return { ...basicValidators, ...paymentMethodErrors }
      }}
    />
  )
}

ReceivePaymentForm.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setDirtyFormState: PropTypes.func.isRequired,
}

export default ReceivePaymentForm
